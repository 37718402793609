
@import "ember-bootstrap/bootstrap";
@import "app/components/a2/bd-callout/bd-callout.scss";
@import "ember-photoswipe";
html {
  height: 100%;
  background-color: white;
}
body {
  height: 100%;
  font-size: 0.9em;

}
.menu {
  background-color: rgb(27, 32, 26);
  // background-color: rgb(248, 244, 190);
}
.submenu {
  font-size: 0.8em;
  padding: 0px;
  background-color: rgb(56, 70, 21);
}
.sidebar{
  //height:100%;
}

.content-wos{
  padding-left: 15px;
}
.w-dropdown-toggle {
  white-space: normal;
}