//bs-callout
.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;
  background-color: white;

}

.bd-callout h4 {
  margin-top: 0;
  margin-bottom: .25rem
}

.bd-callout p:last-child {
  margin-bottom: 0
}

.bd-callout code {
  border-radius: .25rem
}

.bd-callout+.bd-callout {
  margin-top: -.25rem
}

.bd-callout-primary {
  border-left-color: $primary;
}
.bd-callout-primary h4 {
  color: $primary;
}

.bd-callout-info {
  border-left-color: $info;
}
.bd-callout-info h4 {
  color: $info;
}

.bd-callout-warning {
  border-left-color: $warning;
}

.bd-callout-warning h4 {
  color: $warning;
}

.bd-callout-danger {
  border-left-color: $danger;
}

.bd-callout-danger h4 {
  color: $danger;
}
//fin bs-callout
